import React, { Component } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styled-components/workCarousel.scss';
import shortid from 'shortid';
import _map from 'lodash.map';
import _get from 'lodash.get';

import CarouselImageWrapper from './CarouselImageWrapper';
import * as S from './styled-components/work-styles.js';
import ZoomableImage from './zoomableImage';
import { imageBase, sliderSettingsBase } from '../../constants';
import { buildSrcSet } from '../../util';

const renderZoomableImage = (zoomableImage, ratio, state) => {
  return (
    <div key={shortid.generate()}>
      <CarouselImageWrapper isZoomableImage>
        <ZoomableImage image={zoomableImage} ratio={ratio} state={state} />
      </CarouselImageWrapper>
    </div>
  );
};

export const getSliderThumbnails = (images, work) => {
  return _map(images, image => {
    if (image.zoomable) {
      return `${imageBase}${_get(work, 'gridImagelowres')}`;
    }
    return `${imageBase}${_get(image, 'image_1')}`;
  });
};

export default class RenderAllImages extends Component {
  constructor(props) {
    super(props);
    this.slider = React.createRef();
  }
  componentDidMount() {
    if (this.slider.current) {
      this.slider.current.slickGoTo(this.props.currentImage);
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.currentImage !== this.props.currentImage) {
      this.slider.current.slickGoTo(this.props.currentImage);
    }
  }

  render() {
    let { images, ratio, state } = this.props;

    const imagesMapped = _map(images, image => {
      if (image.zoomable) {
        return renderZoomableImage(image, ratio, state);
      }

      return image.isHorizontal || !image.isSquare ? (
        <CarouselImageWrapper key={shortid.generate()}>
          <S.HorizontalMainImage
            src={image.image_1}
            srcSet={buildSrcSet(image)}
            alt={image.alt}
          />
        </CarouselImageWrapper>
      ) : (
        <CarouselImageWrapper key={shortid.generate()}>
          <S.VerticalMainImage
            src={image.image_1}
            srcSet={buildSrcSet(image)}
            alt={image.alt}
          />
        </CarouselImageWrapper>
      );
    });

    const sliderSettings = {
      ...sliderSettingsBase,
      speed: 1000,
      autoplaySpeed: 2000,
      autoplay: false,
      lazyLoad: true,
      dots: false,
    };

    if (images.length > 1) {
      return (
        <Slider {...sliderSettings} ref={this.slider}>
          {imagesMapped}
        </Slider>
      );
    }
    return imagesMapped;
  }
}
