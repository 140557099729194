/* eslint-env browser */
import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';

const CarouselImageWrap = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const ZoomText = styled.p`
  height: 25px;
  font-size: 15px;
  line-height: 25px;
  margin: 0;
  text-align: center;
  width: 100%;
  opacity: ${props => (props.isZoomableImage ? 1 : 0)};
  color: rgba(0, 0, 0, 0.7);
`;

const MagnifierPlus = styled.span`
  border: 1.5px solid rgba(0, 0, 0, 0.7);
  border-radius: 12px;
  display: inline-block;
  width: 19px;
  height: 19px;
  line-height: 15px;
  font-weight: 600;
  font-size: 19px;
  opacity: 0.6;
  transform: translateY(1px);
`;

class CarouselImageWrapper extends Component {
  render() {
    const { children, isZoomableImage } = this.props;

    const zoomTextContent = isMobile
      ? 'Long touch image to see detail'
      : 'Mouse over image to see detail';

    return (
      <CarouselImageWrap>
        {children}
        <ZoomText isZoomableImage={isZoomableImage}>
          <MagnifierPlus>+</MagnifierPlus>&nbsp;{zoomTextContent}
        </ZoomText>
      </CarouselImageWrap>
    );
  }
}

CarouselImageWrapper.propTypes = {
  children: PropTypes.node,
  isZoomableImage: PropTypes.bool,
};

export default CarouselImageWrapper;
