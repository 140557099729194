/* eslint-env browser */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { imageBase } from '../../constants';
import {
  getSmallImageHeight,
  getSmallImageWidth,
  sendAnalytics,
} from '../../util';
import ReactImageMagnify from '../../react-image-magnify-local/ReactImageMagnify';

class ZoomableImage extends Component {
  onZoomImage(title) {
    if (!window.gtag) return; // eslint-disable-line no-console
    sendAnalytics(
      {
        event_category: 'Navigate',
        event_label: `Zoom image ${title}`,
        image_name: title,
      },
      'hover'
    );
  }

  render() {
    const {
      image: {
        alt,
        title,
        smallImageSrc,
        smallImage2xSrc,
        largeImageSrc,
        smallImageWidth,
        smallImageHeight,
        largeImageWidth,
        largeImageHeight,
      },
      ratio,
      state,
    } = this.props;
    const { rotate } = this.props.state;

    const srcSet = smallImage2xSrc
      ? `${imageBase + smallImageSrc} 1x, ${imageBase + smallImage2xSrc} 2x`
      : null;

    return (
      <ReactImageMagnify
        {...{
          smallImage: {
            alt: alt,
            src: `${imageBase + smallImageSrc}`,
            srcSet: `${srcSet}`,
            height: getSmallImageHeight(
              ratio,
              smallImageHeight,
              smallImageWidth,
              state
            ),
            width: getSmallImageWidth(
              ratio,
              smallImageWidth,
              state
            ),
          },
          largeImage: {
            src: `${imageBase + largeImageSrc}`,
            width: largeImageWidth,
            height: largeImageHeight,
            onLoad: () => this.onZoomImage(title),
          },
          enlargedImagePosition: 'over',
          shouldHideHintAfterFirstActivation: false,
          isHintEnabled: false,
          hintTextMouse: 'Mouse Over Image To Zoom',
          hintTextTouch: 'Touch & Hold to Zoom',
          shouldUsePositiveSpaceLens: true,
          rotate: rotate,
          style: {
            transform: `rotate(${rotate}deg)`,
          },
          enlargedImageStyle: {
            transform: `rotate(${rotate}deg)`,
          },
          // hintComponent: CustomHint
        }}
      />
    );
  }
}

ZoomableImage.propTypes = {
  image: PropTypes.object.isRequired,
  state: PropTypes.object,
  ratio: PropTypes.number,
};

export default ZoomableImage;
